import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de consistència dura enganxat directament a l’arbre que parasita, en forma de mitja circumferència, de fins a 20 cm de diàmetre. La superfície és llisa, brillant i marcada de surcs concèntrics de color marró, amb el marge un poc més clar, recte i molt regular. La superfície inferior està coberta de grans porus més o menys hexagonals, molt grossos, de 2 a 3 mm de diàmetre, regulars i de color marró groguenc o marró clar. Té una sèrie de tubs disposats en una sola capa, amb porus amples, d’1-3 mm, i de forma típicament hexagonal. El color és clar al principi, després torna fosc amb tons grisencs negrosos. Les espores són cilíndriques, llises, de 10-13 x 3-5,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      